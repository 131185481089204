import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { scrollToTop } from '../../config/Utils'

const useStyles = makeStyles({
    root: {
        // minHeight: '100vh',
        padding: 30,
        // justifyContent: 'center',
        // alignItems: 'center',
        // display: 'flex'
    }
})
export default function Courses() {
    const classes = useStyles()
    const history = useHistory()
    const [courses, setCourses] = useState([])
    useEffect(() => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/courses/delete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }
    const onComplete = (id) => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/courses/complete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }
    return (
        <Grid container className={classes.root} >
            <Grid item xs={12} style={{ marginBottom: 25 }} >
                <Button variant="outlined" fullWidth onClick={() => { scrollToTop(); history.push('/admin-panel/courses/add') }} >
                    اضافه کردن دوره جدید
                </Button>
            </Grid>
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>عنوان دوره</TableCell>
                            <TableCell align="left">قیمت</TableCell>
                            <TableCell align="left">slug</TableCell>
                            <TableCell align="left">ویرایش</TableCell>
                            <TableCell align="left">تکمیل</TableCell>
                            <TableCell align="left">حذف</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courses.map(row => (
                            <TableRow key={row.id}>
                                <TableCell >
                                    {row.title}
                                </TableCell>
                                <TableCell align="left">{row.price}</TableCell>
                                <TableCell align="left">{row.slug}</TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => {
                                            sessionStorage.setItem('updateCourse', JSON.stringify(row))
                                            history.push('/admin-panel/courses/update')
                                        }}
                                        variant="text" color="primary" >
                                        ویرایش
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    {row.is_complete ? (
                                        <span>دوره کامل شده است</span>
                                    ) : (
                                            <Button onClick={() => onComplete(row.id)} variant="text" color="primary" >
                                                تکمیل دوره
                                            </Button>
                                        )}

                                </TableCell>
                                <TableCell align="left">
                                    <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                        حذف
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
