import React from 'react'
import { Route } from 'react-router-dom'
import Home from '../screens/main/homeScreen'
import Courses from '../screens/main/CoursesScreen'
import CourseDetails from '../screens/main/CourseDetailsScreen'
import LessonDetails from '../screens/main/LessonDetailsScreen'
import Contact from '../screens/main/contactScreen'
import ClassScreen from '../screens/main/ClassScreen'
import ProjectScreen from '../screens/main/ProjectScreen'
import Farshid from '../screens/main/farshidScreen'
import Hamid from '../screens/main/hamidScreen'
import CheckPayScreen from '../screens/main/CheckPayScreen'
import UserPanel from './userPanel'
import AdminPanel from './adminPanel'
import ReactGA from 'react-ga'
ReactGA.initialize('UA-145589039-1')


export default function index({ setLoginModalOpen }) {
    const tracker = ({ location }, InComponent) => {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
        return <InComponent setLoginModalOpen={setLoginModalOpen} />
    }
    return (
        <>
            <Route exact path="/"
                render={(renderProps) => tracker(renderProps, Home)}
            />
            <Route exact path="/courses"
                render={(renderProps) => tracker(renderProps, Courses)}
            />
            <Route exact path="/courses/:slug"
                render={(renderProps) => tracker(renderProps, CourseDetails)}
            />
            <Route exact path="/courses/:slug/:lesson_id"
                render={(renderProps) => tracker(renderProps, LessonDetails)}
            />
            <Route exact path="/home/teachers/farshidkarami"
                render={(renderProps) => tracker(renderProps, Farshid)}
            />
            <Route exact path="/home/teachers/hamidkarami"
                render={(renderProps) => tracker(renderProps, Hamid)}
            />
            <Route exact path="/contact"
                render={(renderProps) => tracker(renderProps, Contact)}
            />
            <Route exact path="/tutor"
                render={(renderProps) => tracker(renderProps, ClassScreen)}
            />
            <Route exact path="/request/project"
                render={(renderProps) => tracker(renderProps, ProjectScreen)}
            />
            <Route exact path="/pay/check/:purchase_id"
                render={(renderProps) => tracker(renderProps, CheckPayScreen)}
            />
            <Route path="/panel">
                <UserPanel />
            </Route>
            <Route path="/admin-panel">
                <AdminPanel />
            </Route>
        </>
    )
}
