/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import './contact.css'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorMessage from '../../components/ErrorMessage'
import SuccessMessage from '../../components/SuccessMessage'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },


}))
export default function CheckPayScreen() {
    const classes = useStyles()
    let history = useHistory()
    let { purchase_id } = useParams()
    const urlParams = new URLSearchParams(window.location.search)
    const Authority = urlParams.get('Authority')
    const Status = urlParams.get('Status')
    const [loading, setLoading] = useState(Status === "OK")
    const [error, setError] = useState(Status !== "OK")
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        if (Status === "OK") {
            let status
            fetch(`https://api.reactnativedeveloper.ir/api/v1/check`, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Authorization': localStorage.token_type + " " + localStorage.token
                },
                body: JSON.stringify({
                    purchase_id: purchase_id,
                    Authority: Authority,
                })
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                }).then((responseJson) => {
                    if (status === 200) {
                        setSuccess(true)
                        setTimeout(() => {
                            setLoading(false)
                            history.push(`/courses/${responseJson.data}`)
                        }, 2000)
                    } else {
                        setError(true)
                        setTimeout(() => {
                            setLoading(false)
                            history.push('/courses')
                        }, 2000)

                    }
                    // console.log(responseJson)
                })
        } else {
            setTimeout(() => {
                history.push('/courses')
            }, 2000)
        }
    }, [])


    return (
        <Grid alignItems="flex-start" container className={classes.root} >
            <Helmet>
                <title>تایید پرداخت</title>
            </Helmet>
            {loading ? (
                <CircularProgress />
            ) : (null)}
            <ErrorMessage
                open={error}
                setOpen={setError}
                message="پرداخت با خطا مواجه شد"
            />
            <SuccessMessage
                open={success}
                setOpen={setSuccess}
                message="پرداخت با موفقیت انجام شد"
            />
        </Grid>
    )
}
