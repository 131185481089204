import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Title from '../../components/title'
import './contact.css'
import Contact from '../../components/contact'
import CallIcon from '@material-ui/icons/CallOutlined'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import TelegramIcon from '@material-ui/icons/Telegram'
import RoomIcon from '@material-ui/icons/RoomOutlined'
import { Helmet } from 'react-helmet'
const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
    },
    contactGrid: {
        paddingTop: 60,
        paddingBottom: 60,
        paddingRight: 30,
        paddingLeft: 30,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 5,
            paddingLeft: 5,
        }

    },
    FormGrid: {
        padding: 10
    },
    detailsGrid: {
        padding: 10
    },
    getintouch: {
        textAlign: 'left'
    },
    iconStyle: {
        fontSize: 30,

    },
    titr: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    pos: {
        marginTop: 15, width: "100%", textAlign: 'left'
    },
    poslist: {
        marginTop: 10, textAlign: 'left'
    }

}))
export default function ClassScreen() {
    const classes = useStyles()
    return (
        <Grid alignItems="flex-start" container className={classes.root} >
            <Helmet>
                <title>درخواست کلاس خصوصی</title>
                <meta name="description" content="دوستانی که به دلیل فعالیت کاری و یا دانشگاهی مجبور به یادگیری سریع تر و انجام پروژه های مرتبط هستند، می توانند از طریق ارتباط مستقیم تلفنی و یا شبکه های اجتماعی درخواست کلاس خصوصی دهند که در صورت حضور مدرس در شهر شما، کلاس به صورت حضوری و در صورت عدم امکان حضور، کلاس به صورت آنلاین برگزار می شود." />
            </Helmet>

            <Grid className={classes.contactGrid} item container xs={12}>
                <Title title="درخواست کلاس خصوصی" />
                <Typography variant="body1">
                    دوستانی که به دلیل فعالیت کاری و یا دانشگاهی مجبور به یادگیری سریع تر و انجام پروژه های مرتبط هستند، می توانند از طریق ارتباط مستقیم تلفنی و یا شبکه های اجتماعی درخواست کلاس خصوصی دهند که در صورت حضور مدرس در شهر شما، کلاس به صورت حضوری و در صورت عدم امکان حضور، کلاس به صورت آنلاین برگزار می شود.
                </Typography>
                <Typography variant="body1" >
                    برای اعلام شرایط خود و درخواست برگزاری کلاس خصوصی میتوانید از طریق راه های زیر با ما ارتباط برقرار نمایید.
                </Typography>
                <Grid container direction='row' >
                    <Grid item xs={12} md={6} className={classes.FormGrid}  >
                        <Contact
                            icon={<CallIcon className={classes.iconStyle} />}
                            title="تلفن تماس"
                            text1="021-66438930"
                            text2="09220479451"
                            text3="09226686036"
                        />
                        <Contact
                            icon={<EmailIcon className={classes.iconStyle} />}
                            title="ایمیل"
                            text1="farshidk0594@gmail.com"
                            text2="haimdk_1373@yahoo.com"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.detailsGrid} >

                        <Contact
                            icon={<TelegramIcon className={classes.iconStyle} />}
                            title="تلگرام / واتساپ"
                            text1="asanyadbegir@"
                            text2="09220479451"
                            text3="09226686036"
                        />
                        <Contact
                            icon={<RoomIcon className={classes.iconStyle} />}
                            title="آدرس"
                            text1="تهران، خیایان دکتر قریب، نبش خیابان طوسی، پلاک 26، ساختمان شباهنگ، واحد 29"
                        />
                    </Grid>
                </Grid>
                <Typography className={classes.pos} variant="h5">
                    مزایای کلاس خصوصی
               </Typography>
                <ul style={{ marginTop: 20 }} >
                    <li className={classes.poslist}  >تسریع روند یادگیری</li>
                    <li className={classes.poslist}  >رفع اشکال حضوری</li>
                    <li className={classes.poslist} >تمرکز بیشتر بر مباحثی که مشکل داشته باشید</li>
                </ul>
                <Typography style={{ marginTop: 15 }} variant="subtitle2">
                    <span className={classes.titr} >توجه: </span>
                    دوره های آموزشی وبسایت به صورت کامل و بدون هیچ نقصی، تمامی مباحث لازم را پوشش خواهد داد.  کلاس خصوصی مختص آن افرادی است که در زمان کمتری قصد یادگیری سریع را دارند و یا به دلیل مشغله کاری و یا تحصیل نیاز به مدرس خصوصی دارند.
                </Typography>
                <Typography style={{ marginTop: 15 }} variant="subtitle2">
                    <span className={classes.titr} >توجه: </span>
                    مراجعه حضوری با هماهنگی قبلی امکان پذیر است.
                </Typography>
            </Grid>
        </Grid>
    )
}
