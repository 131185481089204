import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import canada from '../assets/images/a6.jpg'
import iran1 from '../assets/images/a12.jpg'
import iran2 from '../assets/images/a13.jpg'
import iran3 from '../assets/images/a14.jpg'
import Title from './title'
const styles = theme => ({
    rootPaper: {
        padding: 10,
        marginTop: 15,
        background: 'transparent',
    },
    title: {
        margin: '23px 0 15px 0',
    },
    textBody: {
        marginBottom: 10,
        textAlign: 'justify',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 2,
        padding: 10
    },
    logos: {
        width: '100%',
    },
    imgsalary: {
        width: "80%"
    },
    imgsalaryIran: {
        width: "95%"
    }
})
const Salary = (props) => {
    const { classes } = props
    return (
        <Paper square className={classes.rootPaper}>
            <Grid container >
                <Title title="میزان حقوق برنامه نویسان React Native" />
                <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.textBody}>
                        در حال حاضر گرایش خیلی از شرکت در طراحی اپلیکیشن ها به سمت این فریمورک سوق پیدا کرده است و به همین دلیل بازار کاری خیلی خوبی چه در ایران
                        و چه در خارج از کشور دارد و روزانه چند ده آگهی استخدامی React Native
                        در وب سایت های کاریابی قرار می گیرد.
                        میانگین حقوق برنامه نویسان React Native در داخل کشور بالای پنج میلیون برآورد می شود.
                        میانگین حقوق برنامه نویسان این فریمورک در خارج از کشور نیز بالا می باشد و بالای هشت هزار دلار در سال است.
                        برا اطلاع از میزان حقوق این برنامه نویسان چند آگهی از وب سایت های معتبر کاریابی ایران و کانادا قرار داده شده است.
                    </Typography>
                    <img src={canada} className={classes.imgsalary} alt="آموزش React Native" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={iran1} className={classes.imgsalaryIran} alt="آموزش React Native" />
                    <img src={iran2} className={classes.imgsalaryIran} alt="آموزش React Native" />
                    <img src={iran3} className={classes.imgsalaryIran} alt="آموزش React Native" />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default withStyles(styles)(Salary)