import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#2f3243"
    }
})
export default function Comments() {
    const classes = useStyles()
    const [comments, setComments] = useState([])
    useEffect(() => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/comments', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setComments(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/comments/delete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setComments(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }
    const onActive = (id) => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/comments/active', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setComments(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }


    return (
        <Grid container className={classes.root} >
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>متن نظر</TableCell>
                            <TableCell align="left">کاربر</TableCell>
                            <TableCell align="left">دوره</TableCell>
                            <TableCell align="left">تایید</TableCell>
                            <TableCell align="left">حذف</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comments.map(row => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.comment}</TableCell>
                                <TableCell align="left">{row.user ? row.user.email : ""}</TableCell>
                                <TableCell align="left">{row.course ? row.course.title : ""}</TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => { onActive(row.id) }}
                                        variant="text" color="primary" >
                                        تایید
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                        حذف
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
