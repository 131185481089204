import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Typography from '@material-ui/core/Typography'


function getSteps() {
  return ['معرفی و مقدمات ReactNative',
    'معرفی و آموزش جاوا اسکریپت و اکما اسکریپت',
    'مفاهیم و مباحث مقدماتی تا متوسطه',
    'مفاهیم و مباحث پیشرفته',
    'پروژه: طراحی بخش هایی از اپلیکیشن اینستاگرام',
    'پروژه: طراحی اپلیکیشن خانه هوشمند',
    'پروژه: طراحی اپلیکیشن رزرو بلیط',
    'پروژه: یک اپلیکیشن پیام رسان ساده',
    'مفاهیم و مباحث تکمیلی',
  ]
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `
        در اولین قدم به معرفی فریمورک ReactNative  می پردازیم و با ابزارهای مورد نیاز برای کار با این فریمورک آشنا می شویم. در ادامه ابزار ها را نصب می کنیم و تنظیمات مورد نیاز هر کدام از ابزار ها به طور کامل انجام می دهیم. سپس به معرفیExpo  می پردازیم. و اولین پروژه خود را به دو روش با استفاده از Expo  و ReactNative cli  می سازیم  و خروجی برنامه را بر روی شبیه ساز، گوشی اندروید و آیفون تست می کنیم.
                `
    case 1:
      return `
      در دومین قدم برای مسلط شدن بر ReactNative  به آموزش مباحث مورد نیاز جاوا اسکریپت و اکما اسکریپت می پردازیم. در این مرحله مباحث مهم، پایه ای و  ضروی در حداقل زمان و به صورت کاملا مفهومی آموزش داده می شود و از ذکر مباحث کم اهمیت یا مباحثی که در طراحی اپلیکیشن موبایل مورد نیاز نمی باشند خودداری می کنیم .
      `
    case 2:
      return `
      در این مرحله با ساختار پروژه های ری اکت نیتیو به طور کامل آشنا می شوید. مفاهیمی ماننده  prop  و state  ها به طور کامل و به صورت کاملا مفهومی و کاربردی آموزش داده می شود. همچنین تمام ابزار های موجود در این فریمورک به طور کامل آموزش داده می شود. طراحی ظاهری برنامه، ساخت کامپوننت ها و کتابخانه هایی معروف ری اکت نیتیو ماننده NativeBase  و ReactNativeElements هم در این مرحله از آموزش به طور کامل آموزش داده می شوند.
      `
    case 3:
      return `
      در این مرحله از آموزش به مفاهیم پیشرفته در ری اکت نیتیو می پردازیم. ابزار مدیریت حالت Redux  به طور کامل و مفهومی آموزش داده می شود و نحوه استفاده از آن داخل پروژه به طور مرحله به مرحله آموزش داده می شود. همچنین به مفهوم api  ها و ارتباط اپلیکیشن ها با سرور ها نیز می پردازیم و چگونگی ارسال درخواست به سرور و ارتباط به سرور به طول کامل آموزش داده می شود.
      `
    case 4:
      return `
در این مرحله از آموزش برای مسلط شدن به بخش های طراحی رابط کاربری، بخش هایی از اپلیکیشن اینستاگرام را طراحی می کنیم. با طراحی این بخش ها شما نکات فنی و حرفه ای در طراحی رابط کاربری را 
یادخواهید گرفت.
      `
    case 5:
      return `
در این مرحله از آموزش به پروژه بعدی که طراحی اپلیکیشن خانه هوشمند است می پردازیم. برای مشاهده پروژه ای که طارحی می شود می توانید به قسمت دموی دوره مراجعه کنید.
در این بخش نکاتی را در مورد چگونگی ارتباطات با سرور، ذخیره سازی داده بر روی تلفن همراه و طراحی رابط کاربری حرفه ای و مدرن خواهید آموخت.
      `
    case 6:
      return `
در این مرحله از آموزش یک اپلیکیشن تجاری رزرو بلیط را طراحیی می کنیم. برای مشاهده اپلیکیشنی که طراحی می شود می توانید ویدئوی دموی دوره راا مشاهده کنید.
در این بخش شما یاد می گیرید که چگونه یک اپلیکیشن تجاری و حرفه ای را به صورت استاندارد و حرفه ای طراحی کنید.
      `
    case 7:
      return `
در این مرحله از آموزش براای تسلط بر ارتباطات ریل تایم 
(ارتباطات سوکت) 
که برای برنامه هایی مانند چت و برنامه های با تعداد درخواست بالا استفاده می شود  یک اپلیکیشن پیام رسان ساده را طراحی خواهیم کرد.
      `
    case 8:
      return `
      در این مرحله با  مباحث نهایی مانند چگونگی خروجی گرفتن از برنامه اندروید و آی او اس، چند زبانه کردن برنامه ها، تنظیمات برنامه ها و مباحث مهم باقی مانده آشنا می شوید.  پس از پایان این مرحله شما به طور کامل با این فریمورک آشنا شده اید و می توانید هر پروژه ای را با این فریمورک پیاده سازی کنید.
      `
    default:
      return 'Unknown step'
  }
}

class MainCourseStepper extends React.Component {
  render() {
    const steps = getSteps()
    return (
      <Stepper style={{ backgroundColor: 'transparent' }} orientation="vertical">
        {steps.map((label, index) => {
          return (
            <Step key={index} active={true} >
              <StepLabel>
                <Typography variant="h6" style={{ textAlign: 'right' }}>
                  {label}
                </Typography>
              </StepLabel>
              <StepContent  >
                <Typography variant="subtitle2" >{getStepContent(index)}</Typography>
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
    )
  }
}

export default MainCourseStepper