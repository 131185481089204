import React, { useState, useEffect } from 'react'
import { Modal, Slide, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getImageAddress } from '../config/Utils'
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 40, padding: 20, paddingBottom: 20, position: 'relative',
        width: 800, alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
            width: "80%",
            padding: 5,
            paddingTop: 10,
            maxHeight: "95%",
            overflowY: 'scroll',
        },
        "&:focus": {
            outline: 'none'
        }
    },
}))
export default function NotificationModal() {
    const [open, setOpen] = useState(false)
    const [desktopImage, setDesktopImage] = useState('')
    const [mobileImage, setMobileImage] = useState('')
    const classes = useStyles()

    useEffect(() => {
        if (!(sessionStorage.getItem('notification') === "1")) {
            let status
            fetch('https://api.reactnativedeveloper.ir/api/v1/notification', {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                }
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                }).then((responseJson) => {
                    if (status === 200 && responseJson.data) {
                        setDesktopImage(responseJson.data.desktop_image)
                        setMobileImage(responseJson.data.mobile_image)
                        setOpen(true)
                        sessionStorage.setItem('notification', 1)
                    }
                })
        }
    }, [])
    return (
        <Modal open={open} onClose={() => setOpen(false)} >
            <Slide direction="up" in={open} mountOnEnter unmountOnExit >
                <Container className={classes.root} >
                    <img src={window.screen.width > 600 ? getImageAddress(desktopImage) : getImageAddress(mobileImage)} style={{ maxWidth: "100%" }} alt="آموزش جامع React Native" />
                </Container>
            </Slide>
        </Modal>
    )
}
