export default {
    primary: {
        main: "#00d8ff",
        contrastText: "#FFF",
        backgroundColor: "#2f3243"
    },
    secondary: {
        main: "#ff5fa1"
    },
    text: {
        primary: "#FFF",
        secondary: "#a4acc4",
        disabled: "#b18080"
    }
}