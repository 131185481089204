import React, { useState, useEffect } from 'react'
import { Grid, Button, Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#202442"
    }
})
export default function Notification() {
    const classes = useStyles()
    const [notifications, setNotifications] = useState([])
    const [title, setTitle] = useState([])
    const [desktopImage, setDesktopImage] = useState({})
    const [mobileImage, setMobileImage] = useState(0)
    useEffect(() => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/notification', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setNotifications(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/admin/notification/inactive', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    console.log(responseJson)

                    setNotifications(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    const addProccess = () => {
        let status
        const url = "https://api.reactnativedeveloper.ir/api/v1/admin/notification/add"
        let data = new FormData()
        data.append('title', title)
        data.append('desktop_image', desktopImage)
        data.append('mobile_image', mobileImage)
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
            },
            body: data
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 201) {
                    setNotifications(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    return (
        <Grid container className={classes.root} >
            <Grid item xs={3} style={{ marginBottom: 25 }} >
                <TextField
                    variant="outlined"
                    value={title} onChange={(e) => setTitle(e.target.value)} label="عنوان تخفیف" />
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 25 }} >
                <Button
                    component="label"
                    variant="outlined"
                    color="primary"
                >
                    انتخاب تصویر دسکتاپ
                        <input
                        onChange={(e) => setDesktopImage(e.target.files[0])}
                        type="file"
                        name="desktop"
                        style={{ display: "none" }}
                    />
                </Button>
                <span style={{ margin: 10 }} >{desktopImage ? desktopImage.name : ""}</span>

            </Grid>
            <Grid item xs={3} style={{ marginBottom: 25 }} >
                <Button
                    component="label"
                    variant="outlined"
                    color="primary"
                >
                    انتخاب تصویر موبایل
                        <input
                        name="mobile"
                        onChange={(e) => setMobileImage(e.target.files[0])}
                        type="file"
                        style={{ display: "none" }}
                    />
                </Button>
                <span style={{ margin: 10 }} >{mobileImage ? mobileImage.name : ""}</span>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 25 }} >
                <Button variant="outlined" fullWidth onClick={addProccess} >
                    اضافه / ویرایش
                </Button>
            </Grid>
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>عنوان نوتیفیکییشن</TableCell>
                            <TableCell align="left">غیر فعال کردن</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notifications.map(row => (
                            <TableRow key={row.id}>
                                <TableCell >{row.title}</TableCell>
                                <TableCell align="left">
                                    {row.status === 1 ? (
                                        <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                            غیر فعال کردن
                                        </Button>
                                    ) : (
                                            <Typography color="error" variant="subtitle2" >
                                                غیر فعال است
                                            </Typography>
                                        )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
