import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import { scrollToTop, separate } from '../config/Utils'
const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 30,
        textDecoration: 'none'
    },
    main: {
        cursor: 'pointer',
        position: 'relative',
        height: "100%",
        backgroundColor: "#2f3243",
        zIndex: 1,
        border: "1px solid #2e344e",
        borderTop: "5px solid #2e344e",
        // padding: theme.spacing(8),
        // alignItems: 'center',
        transition: ".4s",
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            transition: ".4s"
        }
    },

    title: {
        marginTop: 15,
        position: 'relative',
        paddingBottom: 15,
        "&::before": {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 'auto',
            bottom: 0,
            height: 2,
            width: 50,
            background: ' #2e344e',
        }
    },
    desc: {
        marginTop: 5,
        textAlign: 'left'
    },
    topImage: {
        width: "100%"
    },
    tag: {
        backgroundColor: "#a2641d",
        position: 'absolute',
        right: 0,
        padding: 5,
        color: '#fff',
    },
    priceGrid: {
        background: ' #193d4a',
        borderRadius: 10,
    }
}))

export default function Course({ title, time, course_image, lessonsCount, price, discountPrice, slug, isComplete, updatedAt }) {
    const classes = useStyles()
    const history = useHistory()
    return (
        <Grid
            component={Link} to={'/courses/' + slug}
            item lg={3} md={6} xs={12} className={classes.root} >
            <Grid className={classes.main} container direction='column' alignItems="flex-start" >
                <img src={course_image} alt={title} className={classes.topImage} />
                <Grid container style={{ padding: 10, borderBottom: "1px solid #2e344e", flex: 1 }}>
                    <Typography className={classes.title} variant="h5">{title}</Typography>
                    <Grid item xs={6} container direction="column" >
                        <Typography className={classes.desc} variant="caption">
                            طول دوره  : {time} ساعت
                        </Typography>
                        <Typography className={classes.desc} variant="caption">
                            تعداد جلسات  : {lessonsCount}
                        </Typography>
                        <Typography className={classes.desc} variant="caption">
                            آخرین به روز رسانی: {updatedAt}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} container direction="column" justify="center" alignItems="center" className={classes.priceGrid} >
                        {
                            price !== discountPrice && (
                                <Typography variant="overline" style={{ textDecoration: 'line-through' }} >
                                    {separate(price)}   تومان
                                </Typography>
                            )
                        }
                        <Typography variant="h6" >
                            {separate(discountPrice)}  تومان
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ padding: 10 }} >
                    <Button fullWidth onClick={() => { scrollToTop(); history.push('/courses/' + slug) }} color="primary" variant="outlined"  >
                        مشاهده جزئیات + ثبت نام
                    </Button>
                </Grid>

                <span className={classes.tag} >{isComplete ? "دوره کامل شده" : "درحال برگزاری"}</span>
            </Grid>
        </Grid>
    )
}
