import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AdminItem from '../../components/AdminItem'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles({
    root: {
        minHeight: '100vh',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }
})
export default function Index() {
    const classes = useStyles()
    const history = useHistory()
    return (
        <Grid container className={classes.root} >
            <AdminItem title="مدیریت دوره ها" onClickProp={() => { history.push('/admin-panel/courses') }} />
            <AdminItem title="مدیریت تاپیک ها" onClickProp={() => { history.push('/admin-panel/topics') }} />
            <AdminItem title="مدیریت درس ها" onClickProp={() => { history.push('/admin-panel/lessons') }} />
            <AdminItem title="مدیریت کد تخفیف ها" onClickProp={() => { history.push('/admin-panel/discounts') }} />
            <AdminItem title="مدیریت پرداخت ها" onClickProp={() => { history.push('/admin-panel/purchases') }} />
            <AdminItem title="مدیریت نظرات" onClickProp={() => { history.push('/admin-panel/comments') }} />
            <AdminItem title="مدیریت کاربر ها" onClickProp={() => { history.push('/admin-panel/users') }} />
            <AdminItem title="مدیریت نوتیفیکیشن ها" onClickProp={() => { history.push('/admin-panel/notifications') }} />
            <AdminItem title="مدیریت گالری ها" onClickProp={() => { history.push('/admin-panel/gallery') }} />

        </Grid>
    )
}
