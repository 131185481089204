import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Title from '../../components/title'
import Course from '../../components/Course'
import { getImageAddress, getTime } from '../../config/Utils'
import Moment from 'moment-jalaali'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        position: 'relative',
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },

}))
export default function CoursesScreen() {
    const classes = useStyles()
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let status
        fetch('https://api.reactnativedeveloper.ir/api/v1/my-courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                    setLoading(false)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    return (
        <div className={classes.root} >
            <Grid direction="row" container style={{ marginTop: 30 }} >
                <Title title="دوره های آموزشی من" />
                {loading ? (
                    <CircularProgress />
                ) : (
                        courses.map((course) =>
                            <Course
                                key={course.id}
                                course_image={getImageAddress(course.course_image)}
                                title={course.title}
                                time={getTime(course.time)}
                                lessonsCount={course.lessons_count}
                                price={course.price}
                                discountPrice={course.price * ((100 - course.discount) / 100)}
                                slug={course.slug}
                                isComplete={course.is_complete}
                                updatedAt={Moment(course.updated_at).format('jD jMMMM jYYYY')}
                            />
                        )
                    )}
            </Grid>

        </div>
    )
}
