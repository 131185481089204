export function validateEmail(email) {
    return !(
        !email ||
        (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,30}$/i.test(email))
    )
}
export function getImageAddress(image) {
    return "https://api.reactnativedeveloper.ir/" + image
}
export function getTime(time) {
    let hour = Math.floor(time / 60)
    let minute = time - (hour * 60)
    return minute + " : " + hour
}
export function scrollToTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
}
export function separate(Number) {
    let x, y, z
    Number += ''
    Number = Number.replace(',', '')
    x = Number.split('.')
    y = x[0]
    z = x.length > 1 ? '.' + x[1] : ''
    var rgx = /(\d+)(\d{3})/
    while (rgx.test(y))
        y = y.replace(rgx, '$1' + ',' + '$2')
    return y + z
}


