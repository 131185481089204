import React, { useState } from 'react'
import { Typography, Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '../assets/images/avatar.png'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
    titr: {
        textAlign: 'justify',
        width: "100%"
    },
    hlDiv: {
        padding: 10,
        backgroundColor: "#2f3243",
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: "1px solid #2e344e",
        marginTop: 10
    },
    preDiv: {
        display: 'flex',
        border: "1px solid #2e344e",
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        marginTop: 20,
        backgroundColor: "#2a2c3b"
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: 80
    },
    name: {
        marginLeft: 10
    },
    time: { fontSize: 12, marginLeft: 10, color: theme.palette.primary.main }
}))
export default function CourseComments({ comments, course_id }) {
    const classes = useStyles()
    const [newComment, setNewComment] = useState('')
    const [parentId, setParentId] = useState(0)
    const [replayName, setReplayName] = useState('')
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const addComment = () => {
        setLoading(true)
        if (!loading) {
            setSuccess(false)
            setError(false)
            let status
            fetch(`https://api.reactnativedeveloper.ir/api/v1/comments/add`, {
                method: "Post",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': localStorage.token_type + " " + localStorage.token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    comment: newComment,
                    parent_id: parentId,
                    course_id: course_id
                })
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                }).then((responseJson) => {
                    if (status === 200) {
                        setParentId(0)
                        setReplayName('')
                        setNewComment('')
                        setSuccess(true)
                    } else {
                        setError(true)
                    }
                    setLoading(false)
                })
        }
    }
    return (
        <>
            <Grid container  >
                {success ? (
                    <Alert style={{ marginBottom: 15 }} severity="success">
                        نظر شما با موفقیت ثبت شد. پس از تایید مدیر به نمایش در می آید.
                    </Alert>
                ) : (null)}
                {error ? (
                    <Alert style={{ marginBottom: 15 }} severity="error">
                        در ارتباط با سرور مشکلی پیش آمده است. دوباره تلاش کنید.
                    </Alert>
                ) : (null)}

                <TextField
                    fullWidth
                    label={localStorage.token && localStorage.token.length > 0 ? parentId === 0 ? "متن نظر خود را بنویسید" : `پاسخ خود را به نظر ${replayName} بنویسید` : "برای ثبت نظر باید وارد شوید"}
                    multiline
                    rows="4"
                    disabled={!(localStorage.token && localStorage.token.length > 0)}
                    variant="outlined"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />
                <Button
                    disabled={newComment.length === 0}
                    onClick={addComment} fullWidth style={{ marginTop: 15 }} variant="outlined" color="primary">
                    {loading ? "در حال ثبت نظر" : localStorage.token && localStorage.token.length > 0 ? "ثبت نظر" : "برای ثبت نظر باید وارد شوید"}
                </Button>
            </Grid >
            {comments.map((comment) =>
                <Grid key={comment.id} container className={classes.preDiv} >
                    <Grid item xs={12} container alignItems="center" justify="space-between" >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                            <img src={comment.user.avatar === "" ? Avatar : comment.user.avatar} className={classes.avatar} alt="" />
                            <Typography variant="subtitle2" className={classes.name} >
                                {comment.user.name}
                            </Typography>
                        </div>
                        <Button
                            onClick={() => { setParentId(comment.id); setReplayName(comment.user.name) }}
                            color="primary" style={{ float: 'left' }} >
                            پاسخ دادن
                </Button>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }} >
                        <Typography className={classes.titr} variant="subtitle2">
                            {comment.comment}
                        </Typography>
                    </Grid>
                    {comment.children.map((c) =>
                        <Grid key={c.id} container className={classes.hlDiv} >
                            <Grid item xs={12} container alignItems="center" justify="space-between" >
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                    <img src={c.user.avatar === "" ? Avatar : c.user.avatar} className={classes.avatar} alt="" />
                                    <Typography variant="subtitle2" className={classes.name} >
                                        {c.user.name}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 10 }} >
                                <Typography className={classes.titr} variant="subtitle2">
                                    {c.comment}
                                </Typography>
                            </Grid>
                        </Grid>

                    )}

                </Grid >
            )}

        </>
    )
}
